var animationTivel = lottie.loadAnimation({
    container: document.getElementById('tivel'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: 'json/data.json'
});

const swiper = new Swiper(".swiper", {
    slidesPerView: 'auto',

    navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
    },
});

$('.mv-wrap__left').on('inview', function(event, isInView) {
    if (isInView) {
        animationTivel.play(); 
    }
});

$('.left-message').on('inview', function(event, isInView) {
    if (isInView) {
        $(this).addClass("fade-txt");
    }
});

$('.content-box__01').on('inview', function(event, isInView) {
    if (isInView) {
        $(this).addClass("fade-box");
    }
});
$('.content-box__02').on('inview', function(event, isInView) {
    if (isInView) {
        $(this).addClass("fade-box");
    }
});
$('.content-box__03').on('inview', function(event, isInView) {
    if (isInView) {
        $(this).addClass("fade-box");
    }
});

let logo = $("#logo");
let metaball = $("#metaball-pointer");

let offset_top = logo.offset().top;
let height = logo.height() / 2;
let meta_top = offset_top + height;

if (window.matchMedia('(max-width: 767px)').matches) {
    metaball.css("top",meta_top);
}